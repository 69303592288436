import { SVGProps, Ref, forwardRef } from 'react'

const SvgChevronLeft = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path stroke="currentColor" strokeWidth={2} d="M15 18.65 7.85 11.5 15 4.35" />
  </svg>
)

const ForwardRef = forwardRef(SvgChevronLeft)
export default ForwardRef
